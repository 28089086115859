// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiReferenciaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Billing de tus clientes</h2>
                <p>
                    El recurso de <b>clients billing</b> permite obtener estadísticas asociadas a las cuentas de tus clientes. Por lo que puedes usarla para realizar cobros independientes, reventa de las facturas, etc.
                </p>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/clients/billing/find/<b><span className="error">:masterToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>masterToken: </b> Esta es <span className="error">la llave de acceso principal</span>, Indicando que <b>las variables de raíz pertenecen a tu usuario</b>.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>month | year:</b> Mes y año al cual pertenece esta cuenta
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>paid:</b> Boolean, si la cuenta ha sido saldada o no
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>type:</b> <code>service | subscription</code>, el tipo de cargo pendiente. <br /> Service es por costo de facturación, y Subscription es por mensualidad en caso de plan integrador.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>owed | owedTax:</b> Cantidad a cobrar, separada en subtotal e impuestos.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>stamped:</b> Cantidad de facturas en producción emitidas en este cargo, comunmente por mes, al menos que se realice un corte manual antes de tiempo.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>stampedTest:</b> Cantidad de facturas simuladas emitidas durante el plazo de este cargo.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>canceled:</b> Cantidad de facturas canceladas, indiferente si eran pruebas o reales.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>distribution:</b> Distribución de la cuenta según la actividad de todos tus clientes durante este periodo. <br />
                            <span className="quote small">Aquí se distribuye el uso de facturas por authToken, el valor de las variables es la misma que las mencionadas anteriormente</span>

                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/cfdi-minimum`} previous={`/api/referencia/clientes-buscar`}/>
            </div>
            <div className="side">
                <CodeBlock title="Client Billing" requests={clienteScripts.billing.requests} response={clienteScripts.billing.response} />
            </div>
        </div>
    </Layout>
)

export default ApiReferenciaLanding
